.ib-header {
  padding: 30px 0;
  background-image: url('../../../images/headerBg.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center right;
  background-color: #ffffff;
  border-bottom: 1px solid #dcdcdc;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
}
