.ib-error {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #eb0010;
  margin-bottom: 10px;
}

.ib-error img {
  margin-right: 10px;
}
