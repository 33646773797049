body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'sans-serif', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  height: 100%;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #555555;
}

body {
  background-color: #F8F9F9;
}

*:focus {
  box-shadow: 0px 0px 0px 2px #E59700;
  outline: none;
}

.ib-container {
  max-width: 782px;
  margin: 0 auto;
  padding: 0 15px;
}

.ib-container a {
  color: #005EB8;
}

.ib-list-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ib-list-menu li {
  margin-bottom: 10px;
}

.ib-list-menu li a {
  text-decoration: none;
}

/* step navigation */

.ib-step-navigation {
  margin-bottom: 35px;
}

.ib-step-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ib-step-navigation ul li {
  width: 15%;
  text-align: center;
  text-decoration: none;
  color: #1E2022;
}

.ib-step-navigation ul li a {
  text-decoration: none;
  display: block;
  color: #707070;
  position: relative;
}

.ib-step-navigation ul li a:after,
.ib-step-navigation ul li a:before {
  display: block;
  content: " ";
  position: absolute;
  top: 20px;
  right: 0px;
  height: 1px;
  background-color: #B6B8BA;
  width: 47%;
  z-index: 1;
}

.ib-step-navigation ul li:first-child a:before {
  display: none;
}

.ib-step-navigation ul li:last-child a:after {
  display: none;
}

.ib-step-navigation ul li a:before {
  left: 0;
}

.ib-step-navigation__circle {
  display: block;
  position: relative;
  margin: 0 auto 10px;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background-color: #FFFFFF;
  border: 1px solid #B6B8BA;
  border-radius: 50px;
  text-align: center;
  z-index: 2;
  color: #B6B8BA;
  font-weight: 700;
}

.ib-step-navigation__active a {
  color: #1E2022;
}

ul li.ib-step-navigation__active a:before,
ul li.ib-step-navigation__active a:after {
  height: 2px;
  background-color: #003F93;
}

ul li.ib-step-navigation__active.current a:after {
  height: 1px;
  background-color: #B6B8BA;
}

.ib-step-navigation__active .ib-step-navigation__circle {
  background-color: #D7EFFF;
  border-color: #003F93;
  color: #003F93;
}



h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  font-size: 26px;
  margin-bottom: 35px;
  color: #1E2022;
}

h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #1E2022;
}

h3 {
  font-size: 22px;
  margin-bottom: 16px;
  color: #1E2022;
}

h4 {
  font-size: 20px;
  margin-bottom: 16px;
  color: #1E2022;
}

h5 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #1E2022;
}

h6 {
  font-size: 16px;
  margin-bottom: 16px;
  color: #1E2022;
}

p {
  font-size: 18px;
  margin-bottom: 25px; 
}

.text-dark-warning {
  color: #844C18;
}

.ib-text-danger {
  color: #eb0010;
}
.ib-text-success {
  color: #2aa124;
}

.ib-info-block {
  display: flex;
  justify-content: start;
  margin-bottom: 25px;

}

.ib-info-block.error {
  margin-bottom: 15px;
}

.ib-info-block.error .ib-info-block__icon {
  min-width: 45px;
}

.ib-info-block__icon {
  margin-right: 15px;
}

.ib-info-block__text.small .ib-info-block__heading,
.ib-info-block__text.small .ib-info-block__description {
  font-size: 16px;
}

.ib-info-block__heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #1E2022;
}

.ib-info-block__description {
  font-size: 18px;
  line-height: 26px;
  color: #707070;
}

.ib-upload-file-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ib-upload-file-list li {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ib-upload-file-list__file-info {
  display: flex;
  align-items: center;
}

.ib-upload-file-list__file-info.process {
  color: #707070;
}

.ib-upload-file-list__indicator {
  margin-left: 10px;
}

.ib-tree-view.dx-treeview {
  height: 375px;
  background: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 45px;
}

.ib-table {
  width: 100%;
}

.ib-table td,
.ib-table th {
  padding: 10px;
  color: #1E2022;
}
