.ib-page-template {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.ib-page-template__content {
  padding: 30px 0 80px;
  flex: 1;
}
