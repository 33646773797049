.collapsible-content {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  /* border-bottom: 1px solid #dee2e6 !important; */
}
.title-text {
  display: block;
  font-size: 1em;
  margin-block-start: 10px;
  margin-block-end: 10px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.collapsible-header {
  display: flex;
  justify-content: space-between;
  padding: 2px 0px 2px 0px;
  align-items: center;
  color: #005eb8;
  font-size: 16px;
  font-weight: 700;
  /* border-bottom: 1px solid #dee2e6 !important; */
}
.collapsible-content-padding {
  padding: 10px 0px;
}
.rotate-center {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate-center.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-center.up {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.collapsible-icon-button {
  cursor: pointer;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: block;
  width: 100%;
  padding: 0;
}

.collapsible-card {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  margin: 5px 0;
}

.collapsible-card:hover {
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
}

.dx-swatch-induction-scheme .dx-icon-custom-colllapse {
  font-size: 25px;
}
