.ib-footer {
  padding: 60px 0 50px;
  background-color: #eff8ff;
  border-top: 1px solid #dcdcdc;
  /* color: white; */
}

.ib-footer a {
  color: #003f93;
}

.ib-footer__sub-text {
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
}
